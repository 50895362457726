<template>
    <v-alert
        border="left"
        colored-border
        type="info"
        elevation="2"
    >
        {{ $trans('questions.hints.free_answer_set') }}
    </v-alert>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'
export default {
    name: 'FreeAnswerInput',
    mixins: [questionsTypeBasePropsMixin],
    mounted() {
        this.$emit('input', {
            correct: 'free_answer'
        })
    }
}
</script>

<style scoped>

</style>
